<template>
    <div class="table">
        <el-table
            :data="tableData"
            class="table-box"
            ref="multipleTable"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column label="联系方式" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span
                        v-if="
                            scope.row.contactList &&
                            scope.row.contactList.length > 0
                        "
                        >{{ scope.row.contactList[0].contactPhone }}</span
                    >
                </template>
            </el-table-column>
            <el-table-column label="联系人" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span
                        v-if="
                            scope.row.contactList &&
                            scope.row.contactList.length > 0
                        "
                        >{{ scope.row.contactList[0].contactName }}</span
                    >
                </template>
            </el-table-column>
            <el-table-column
                prop="address"
                label="其他联系方式"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span>{{
                        scope.row.weChat
                            ? '微信号:' + scope.row.weChat
                            : scope.row.qq
                            ? 'QQ号:' + scope.row.qq
                            : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="companyName"
                label="公司名称"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span
                        @click="onDetail(scope.row)"
                        style="color: #2370eb; cursor: pointer"
                    >
                        {{ scope.row.companyName || '- -' }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column
                prop="companyDomain"
                label="域名"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="userNo" label="用户数" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="原邮箱品牌" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{
                        $tableDataHandle.primaryEmailBrand(scope.row)
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column label="线索状态" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>
                        {{
                            scope.row.customerClueStatus == 1
                                ? '未分配'
                                : scope.row.customerClueStatus == 2
                                ? '待分配'
                                : scope.row.customerClueStatus == 3
                                ? '待接收'
                                : scope.row.customerClueStatus == 4
                                ? '待定'
                                : scope.row.customerClueStatus == 5
                                ? '已退回'
                                : scope.row.customerClueStatus == 6
                                ? '转为客户'
                                : scope.row.customerClueStatus == 7
                                ? '商机'
                                : scope.row.customerClueStatus == 8
                                ? '已完成'
                                : scope.row.customerClueStatus == 9
                                ? '关闭'
                                : '- -'
                        }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column
                prop="clueFollowAdminName"
                label="协助跟进人"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
                prop="createAdminName"
                label="创建人"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
                prop="createTime"
                label="创建时间"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="opt" label="操作" fixed="right">
                <template slot-scope="scope">
                    <div>
                        <el-button type="text" @click="onCustomer(scope.row)">
                            转为客户
                        </el-button>
                        <el-button type="text" @click="onDelete(scope.row)">
                            删除
                        </el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    name: '',
    props: {},
    data() {
        return {
            tableData: [],
        };
    },
    components: {},
    created() {},
    methods: {
        onDetail(data) {
            this.$emit('onDetail', data);
        },
        onCustomer(data) {
            this.$emit('onCustomer', data);
        },
        onDelete(data) {
            this.$emit('onDelete', data);
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.table {
    width: 100%;
    height: 100%;
}
</style>
