<template>
    <div class="customerFrom">
        <el-form label-width="90px" label-position="left" size="small">
            <el-form-item label="公司名称" required>
                <el-input
                    v-model="customerDO.companyName"
                    placeholder="请输入"
                ></el-input>
            </el-form-item>
            <el-form-item label="域名">
                <el-input
                    v-model="customerDO.companyDomain"
                    placeholder="请输入"
                ></el-input>
            </el-form-item>
            <template v-for="(item, index) in customerDomainList">
                <div :key="index">
                    <el-form-item label="关联域名">
                        <el-input
                            style="width:240px"
                            v-model="item.domain"
                            placeholder="关联域名"
                            size="small"
                        ></el-input>
                        <el-button
                            v-if="index == 0"
                            @click="addDomain"
                            type="primary"
                            class="el-icon-plus"
                            circle
                            size="mini"
                        ></el-button>
                        <el-button
                            v-if="index > 0"
                            @click.prevent="removeDomain(index)"
                            class="el-icon-minus"
                            type="primary"
                            circle
                            size="mini"
                        ></el-button>
                    </el-form-item>
                </div>
            </template>

            <el-form-item label="联系方式">
                <div
                    class="info contact_way_box"
                    v-for="(item, index) in customerDO.customerMainContactList"
                    :key="index"
                >
                    <el-button
                        v-if="index > 0"
                        type="primary"
                        @click="toTop(index)"
                        class="el-icon-top ordinary"
                        circle
                        size="mini"
                    ></el-button>
                    <el-button
                        v-if="index > 0"
                        type="primary"
                        @click="toTop(index)"
                        class="el-icon-top ordinary"
                        circle
                        size="mini"
                    ></el-button>
                    <el-select
                        v-model="item.contactWay"
                        size="small"
                        style="width:85px"
                        clearable
                        placeholder="方式"
                    >
                        <el-option label="电话" :value="1"></el-option>
                        <el-option label="微信" :value="2"></el-option>
                        <el-option label="邮箱" :value="3"></el-option>
                        <el-option label="QQ" :value="4"></el-option>
                        <el-option label="短信" :value="5"></el-option>
                        <el-option label="拜访" :value="6"></el-option>
                        <!--  <el-option label="官网表单" :value="8"></el-option> -->
                        <el-option label="座机" :value="9"></el-option>
                        <el-option label="其他" :value="7"></el-option>
                    </el-select>
                    <el-input
                        style="margin-left:5px;width:150px"
                        v-model="item.contactContent"
                        placeholder="请输入"
                        size="small"
                    ></el-input>
                    <el-button
                        v-if="index == 0"
                        @click="addContact"
                        type="primary"
                        class="el-icon-plus"
                        circle
                        size="mini"
                    ></el-button>
                    <el-button
                        v-if="index > 0"
                        @click.prevent="removeContact(index)"
                        class="el-icon-minus"
                        type="primary"
                        circle
                        size="mini"
                    ></el-button>
                </div>
            </el-form-item>
            <el-form-item label="原邮箱品牌">
                <el-select
                    v-model="customerDO.primaryEmailBrand"
                    size="small"
                    style="width:240px"
                    clearable
                    placeholder="原邮箱品牌"
                >
                    <el-option
                        v-for="item in $searchForm.mailboxbrand()"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="到期时间">
                <el-date-picker
                    v-model="customerDO.customerExpireTime"
                    type="date"
                    value-format="yyyy-MM-dd hh:mm:ss"
                    placeholder="选择日期"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item label="客户标签">
                <span
                    :key="index"
                    :class="item.category == 0 ? 'blue' : 'yellow'"
                    class="labelList"
                    v-for="(item, index) in $choiceLabel.state.labelList"
                    >{{ item.tagName }}
                    <span class="cancel" @click="onDeleteLabel(item)">
                        <span class="el-icon-close"></span>
                    </span>
                </span>
                <el-button
                    type="primary"
                    icon="el-icon-plus"
                    size="small"
                    @click="onChoiceLabel"
                    style="background:rgba(0, 0, 0, 0.02);border-color:rgba(0, 0, 0, 0.15);color:#000000;padding:9px"
                ></el-button>
            </el-form-item>
            <el-form-item label="所在地">
                <el-cascader
                    placeholder="所在地"
                    style="width:240px"
                    clearable
                    v-model="address"
                    :options="$address.cascader()"
                ></el-cascader>
            </el-form-item>
            <el-form-item
                label="邮箱版本"
                v-if="
                    customerDO.primaryEmailBrand == 9 ||
                        customerDO.primaryEmailBrand == 10 ||
                        customerDO.primaryEmailBrand == 8 ||
                        customerDO.primaryEmailBrand == 35
                "
            >
                <el-select
                    v-model="customerDO.emailVersionType"
                    size="small"
                    style="width:240px"
                    clearable
                    placeholder="邮箱版本"
                >
                    <el-option
                        label="全新用户"
                        v-if="
                            customerDO.primaryEmailBrand == 9 ||
                                customerDO.primaryEmailBrand == 10
                        "
                        :value="1"
                    ></el-option>
                    <el-option
                        label="已有新版基础版"
                        v-if="customerDO.primaryEmailBrand <= 1"
                        :value="2"
                    ></el-option>
                    <el-option
                        label="已有旧版基础版"
                        v-if="customerDO.primaryEmailBrand <= 1"
                        :value="3"
                    ></el-option>
                    <el-option
                        label="阿里-标准版"
                        v-if="
                            customerDO.primaryEmailBrand == 8 ||
                                customerDO.primaryEmailBrand == 35
                        "
                        :value="4"
                    ></el-option>
                    <el-option
                        label="阿里-尊享版"
                        v-if="
                            customerDO.primaryEmailBrand == 8 ||
                                customerDO.primaryEmailBrand == 35
                        "
                        :value="5"
                    ></el-option>
                    <el-option
                        label="阿里-集团版"
                        v-if="
                            customerDO.primaryEmailBrand == 8 ||
                                customerDO.primaryEmailBrand == 35
                        "
                        :value="6"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item
                v-if="customerDO.primaryEmailBrand == 1"
                :label="
                    customerDO.emailVersionType == 1
                        ? '手机号'
                        : customerDO.emailVersionType == 2
                        ? '企业ID'
                        : '管理员账号'
                "
            >
                <el-input v-model="customerDO.emailVersionTypeName"></el-input>
            </el-form-item>
            <el-form-item label="所属行业">
                <el-select
                    v-model="customerDO.business"
                    size="small"
                    style="width:240px"
                    clearable
                    placeholder="所属行业"
                >
                    <el-option
                        v-for="item in $industry.industry()"
                        :label="item.label"
                        :value="item.value"
                        :key="item.value"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="预计用户数">
                <el-input
                    v-model="customerDO.number"
                    placeholder="请输入"
                    @input="
                        customerDO.number = customerDO.number.replace(
                            /[^0-9.]/g,
                            ''
                        )
                    "
                ></el-input>
            </el-form-item>
            <el-form-item label="预计年限">
                <el-input
                    v-model="customerDO.term"
                    placeholder="请输入"
                    @input="
                        customerDO.term = customerDO.term.replace(
                            /[^0-9.]/g,
                            ''
                        )
                    "
                ></el-input>
            </el-form-item>
            <el-form-item label="地址">
                <el-input
                    v-model="customerDO.address"
                    placeholder="请输入"
                ></el-input>
            </el-form-item>
            <el-form-item label="联系备注">
                <el-input
                    v-model="customerDO.contactRemark"
                    placeholder="请输入"
                ></el-input>
            </el-form-item>
            <el-form-item label="备注">
                <el-input
                    type="textarea"
                    style="width:240px"
                    rows="6"
                    placeholder="请输入"
                    v-model="customerDO.remark"
                ></el-input>
            </el-form-item>
        </el-form>
        <el-dialog
            :visible.sync="dialogChoice"
            width="560px"
            :before-close="handleClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="display:flex; align-items: center;color:#000000;font-size:16px;font-weight: 500;"
                >
                    选择标签
                </div>
            </template>
            <ChoiceLabel
                @close="handleClose"
                @addLabel="addLabel"
                :isGet="isGet"
                :labelType="labelType"
            />
        </el-dialog>
        <el-dialog
            :visible.sync="dialogAddlabel"
            width="560px"
            :before-close="handleAddlabel"
            append-to-body
        >
            <template slot="title">
                <div
                    style="display:flex; align-items: center;color:#000000;font-size:16px;font-weight: 500;"
                >
                    新增个人标签
                </div>
            </template>
            <AddLable @close="handleAddlabel" @addLabel="addLabel" />
        </el-dialog>
    </div>
</template>

<script>
import ChoiceLabel from '../../../../components/choice-label/choiceLabel.vue';
import AddLable from '../../../../components/choice-label/addLabel.vue';
export default {
    name: '',
    props: ['formData'],
    data() {
        return {
            // 客户基本信息
            customerDO: {
                companyName: '',
                companyDomain: '',
                customerMainContactList: [],
                number: '',
                remark: '',
            },
            // 客户标签列表
            customerTagList: [],
            // 客户域名列表
            customerDomainList: [],
            address: '',
            dialogChoice: false,
            dialogAddlabel: false,
            isGet: false,
            labelType: '',
            formDataId: '',
        };
    },
    components: {
        ChoiceLabel,
        AddLable,
    },
    // watch: {
    //   formData: {
    //     deep: true,
    //     handler(val) {
    //       if (this.formDataId == val.id) {
    //         return;
    //       }

    //     },
    //   },
    // },
    mounted() {},
    methods: {
        // 添加域名
        addDomain() {
            this.customerDomainList.push({});
        },
        // 删除域名
        removeDomain(index) {
            this.customerDomainList.splice(index, 1);
        },
        // 添加联系方式
        addContact() {
            this.customerDO.customerMainContactList.push({
                contactWay: 1,
                contactContent: '',
            });
        },
        // 删除联系方式
        removeContact(index) {
            this.customerDO.customerMainContactList.splice(index, 1);
        },
        // 新增标签
        addLabel() {
            this.dialogChoice = false;
            this.dialogAddlabel = true;
        },
        // 选择标签
        onChoiceLabel() {
            // console.log(this.$choiceLabel.state.labelList);
            this.dialogChoice = true;
            let that = this;
            setTimeout(() => {
                that.isGet = !that.isGet;
                this.$choiceLabel.commit('isMultiple', false);
                this.$choiceLabel.commit('getType', '2');
            }, 200);
        },
        // 移除标签
        onDeleteLabel(item) {
            this.$choiceLabel.commit('removeLabel', JSON.stringify(item));
        },
        handleClose() {
            this.dialogChoice = false;
        },
        handleAddlabel() {
            this.dialogAddlabel = false;
            this.dialogChoice = true;
            this.isGet = !this.isGet;
        },
        // 置顶
        toTop(index) {
            let arr = this.customerDO.customerMainContactList.splice(index, 1);
            // console.log(arr);
            this.customerDO.customerMainContactList.unshift(arr[0]);
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.customerFrom {
    background-color: #fff;
    width: 100%;
    margin-top: 10px;
    /deep/ .el-textarea__inner {
        resize: none;
    }
}
.el-input {
    width: 240px;
}

.el-icon-circle-plus-outline,
.el-icon-remove-outline {
    color: #2370eb;
    font-size: 20px;
    vertical-align: middle;
    margin-left: 5px;
    cursor: pointer;
}
.el-button--mini.is-circle {
    padding: 2px;
    background: #fff;
    color: #2370eb;
    font-weight: 600;
    width: 20px;
    height: 20px;
    border: 2px solid #2370eb;
    margin-left: 17px;
}
.customerFrom .contact_way_box {
    background-color: #fff;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding-left: 0;
    margin-bottom: 10px;
    position: relative;
}

.labelList {
    display: inline-block;
    padding: 0 8px;
    height: 30px;
    border: 1px solid #e5e5e5;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    line-height: 30px;
    margin-right: 32px;
    cursor: pointer;
    margin-bottom: 20px;
    position: relative;
    .cancel {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-top: 10px solid rgba(255, 255, 255, 0);
        border-right: 10px solid rgba(255, 255, 255, 0);
        border-bottom: 10px solid rgba(255, 255, 255, 0);
        border-left: 10px solid yellow;
        -webkit-transform: rotate(45deg); /* for Chrome || Safari */
        -moz-transform: rotate(45deg); /* for Firefox */
        -ms-transform: rotate(45deg); /* for IE */
        -o-transform: rotate(45deg);
        right: -10px;
        bottom: -10px;
        .el-icon-close {
            color: #fff;
            position: absolute;
            font-size: 8px;
            left: -12px;
            top: -6px;
            -webkit-transform: rotate(45deg); /* for Chrome || Safari */
            -moz-transform: rotate(45deg); /* for Firefox */
            -ms-transform: rotate(45deg); /* for IE */
            -o-transform: rotate(45deg);
        }
    }
}
.blue {
    color: #2370eb;
    border-color: #2370eb;
    .cancel {
        border-left: 10px solid #2370eb;
    }
}
.yellow {
    color: #fa8c15;
    border-color: #fa8c15;
    .cancel {
        border-left: 10px solid #fa8c15;
    }
}
.el-button--mini.is-circle.ordinary {
    color: #999999;
    border-color: #999999;
    margin-left: 0;
    width: 20px;
    height: 20px;
    position: absolute;
    left: -30px;
    top: 50%;
    transform: translateY(-50%);
}
.el-button--mini.is-circle.ordinary:hover {
    background: #fff;
    color: #2370eb;
    border: 2px solid #2370eb;
}
</style>
