<template>
    <div class="basic_info">
        <el-form label-position="left" label-width="90px" size="small">
            <el-form-item label="公司名称">
                <div class="info">
                    {{ basicData.companyName || '- -' }}
                </div>
            </el-form-item>
            <el-form-item label="域名">
                <div class="info">
                    {{ basicData.companyDomain || '- -' }}
                </div>
            </el-form-item>
            <el-form-item
                label="联系人"
                v-if="basicData.contactList && basicData.contactList.length > 0"
            >
                <div class="info">
                    {{ basicData.contactList[0].contactName || '- -' }}
                </div>
            </el-form-item>
            <el-form-item
                label="联系手机"
                v-if="basicData.contactList && basicData.contactList.length > 0"
            >
                <div class="info">
                    {{ basicData.contactList[0].contactPhone || '- -' }}
                </div>
            </el-form-item>
            <el-form-item label="用户数">
                <div class="info">
                    {{ basicData.userNo || '- -' }}
                </div>
            </el-form-item>
            <el-form-item label="线索来源">
                <div class="info">
                    {{ $tableDataHandle.clueSource(basicData) }}
                </div>
            </el-form-item>
            <el-form-item label="线索类型">
                <div class="info">
                    {{
                        basicData.clueType == 1
                            ? '售前咨询'
                            : basicData.clueType == 2
                            ? '购买咨询'
                            : basicData.clueType == 3
                            ? '售后'
                            : basicData.clueType == 4
                            ? '基础版注册'
                            : '- -'
                    }}
                </div>
            </el-form-item>
            <el-form-item label="线索标签">
                <div v-if="customerTagList.length > 0">
                    <em
                        v-for="item in customerTagList"
                        :key="item.id"
                        :class="item.category == 0 ? 'blue' : 'orange'"
                    >
                        {{ item.tagName }}
                    </em>
                </div>
                <div v-else class="info">- -</div>
            </el-form-item>
            <el-form-item label="原邮箱品牌">
                <div class="info">
                    {{ $tableDataHandle.primaryEmailBrand(basicData) }}
                </div>
            </el-form-item>
            <el-form-item label="QQ号">
                <div class="info">
                    {{ basicData.qq || '- -' }}
                </div>
            </el-form-item>
            <el-form-item label="微信号">
                <div class="info">
                    {{ basicData.weChat || '- -' }}
                </div>
            </el-form-item>
            <el-form-item label="联系邮箱">
                <div class="info">
                    {{ basicData.email || '- -' }}
                </div>
            </el-form-item>
            <el-form-item label="品牌">
                <div class="info">
                    {{
                        $tableDataHandle.primaryEmailBrand({
                            primaryEmailBrand: basicData.intentionEmailBrand,
                        })
                    }}
                </div>
            </el-form-item>
            <el-form-item label="所在地">
                <div class="info">
                    {{
                        $address.address(basicData.provinceId, basicData.cityId)
                    }}
                </div>
            </el-form-item>
            <el-form-item label="备注">
                <div class="info">
                    {{ basicData.remark || '- -' }}
                </div>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { relationList } from '@/api/user/label.js';
export default {
    name: '',
    props: ['basicData'],
    data() {
        return {
            customerTagList: [],
        };
    },
    watch: {
        basicData: {
            deep: true,
            handler(val) {
                val.id ? this.relationList(val.id) : '';
            },
        },
    },
    components: {},
    created() {},
    methods: {
        // 获取所属标签
        relationList(id) {
            let data = {
                param: {
                    bandingTableType: 2,
                    bandingId: id,
                },
            };
            relationList(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.customerTagList = res.data.list;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.basic_info {
    width: 100%;
    height: calc(100%);
    overflow-y: auto;
    .info {
        background-color: #f9f9f9;
        padding-left: 10px;
    }
    .el-form {
        width: 70%;
        margin: 20px auto;
    }
    em {
        display: inline-block;
        padding: 0 8px;
        height: 30px;
        border: 1px solid #e5e5e5;
        border-radius: 2px;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        line-height: 30px;
        margin-right: 10px;
        margin-bottom: 8px;
        font-style: normal;
    }
    em.blue {
        border-color: #2370eb;
        color: #2370eb;
    }
    em.orange {
        border-color: #fa8c15;
        color: #fa8c15;
    }
}
</style>
