<template>
    <div class="customer_box">
        <div class="search-box">
            <div class="search-one">
                <p class="left">
                    <span>公司名称</span>
                    <el-input
                        size="small"
                        style="width:160px"
                        placeholder="请输入"
                        v-model.trim="searchVal.companyName"
                        clearable
                    >
                    </el-input>
                    <el-button
                        @click="getData()"
                        type="primary"
                        size="small"
                        icon="el-icon-search"
                    ></el-button>
                </p>
                <!-- <p>
          <el-button
            class="right"
            type="primary"
            style="margin-left:29px"
            size="small"
            @click="getData()"
            >查询</el-button
          >
          <el-button
            class="right"
            @click="searchEmpty()"
            type="primary"
            style="margin:0;background:none;border-color:#D9D9D9;color:#333333"
            icon="el-icon-refresh-left"
            size="small"
            >重置</el-button
          >
          <span
            @click="changeStatus"
            style="color:#2162CB;font-size:12px;cursor: pointer;"
            >{{ tag ? "收起" : "展开" }}
            <i :class="tag ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
          </span>
        </p> -->
            </div>
        </div>
        <div class="table">
            <Table
                ref="table"
                @onDetail="onDetail"
                @onCustomer="onCustomer"
                @onDelete="onDelete"
            />
        </div>
        <div class="page-box">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 详情 -->
        <el-drawer
            :visible.sync="drawerDetail"
            :direction="direction"
            :before-close="handleClose"
            size="880px"
        >
            <template slot="title">
                <div
                    style="display:flex; align-items: center;font-size:16px;font-weight: 500;color: rgba(0, 0, 0, 0.85);line-height: 24px;"
                >
                    详情
                </div>
            </template>
            <Detail @close="handleClose" ref="detail" />
        </el-drawer>
        <!-- 转为客户 -->
        <el-drawer
            :visible.sync="drawerAddCustomer"
            :direction="direction"
            :before-close="handleClose"
            size="880px"
        >
            <template slot="title">
                <div
                    style="display:flex; align-items: center;font-size:16px;font-weight: 500;color: rgba(0, 0, 0, 0.85);line-height: 24px;"
                >
                    转为客户
                </div>
            </template>
            <AddCustomer
                @close="handleClose"
                @addClue="addClueDrawer"
                @update="update"
                ref="addCustomer"
            />
        </el-drawer>
        <!-- 保存并新建线索 -->
        <el-drawer
            :visible.sync="drawerAddClue"
            :direction="direction"
            :before-close="handleClose"
            size="560px"
        >
            <template slot="title">
                <div
                    style="display:flex; align-items: center;font-size:16px;font-weight: 500;color: rgba(0, 0, 0, 0.85);line-height: 24px;"
                >
                    客户新增线索
                </div>
            </template>
            <AddClue
                @close="handleClose"
                @onSubmit="onSubmitClue"
                ref="addClue"
            />
        </el-drawer>
        <!-- 输单 弹窗 -->
        <el-dialog
            :visible.sync="dialog"
            width="560px"
            :before-close="handleClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="display:flex; align-items: center;color:#000000;font-size:16px;font-weight: 500;"
                >
                    删除
                </div>
            </template>
            <div class="dialog_info">
                <p style="margin-bottom:15px">
                    即将删除本条线索，是否确定？
                </p>
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button @click="handleClose" round size="mini"
                    >取 消</el-button
                >
                <el-button type="primary" @click="updateClue" round size="mini"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { oldClueList, updateClue } from '@/api/user/clue.js';
import { getCustomerId } from '@/api/user/ae/customer.js';
import { addClue as customerAddClue } from '@/api/user/clue.js';
import Table from '../table/table.vue';
import Detail from '../detail/detail.vue';
import AddClue from '../../../database/addClue/addClue.vue';
import AddCustomer from '../addCustomer/addCustomer.vue';
export default {
    name: '',
    props: {},
    data() {
        return {
            searchVal: {
                name: '',
                customerClueStatus: '',
            },
            pagesize: 20,
            currentPage: 1,
            total: 0,
            primaryEmailBrandList: [],
            departmentRoleList: [],
            btnP: {},
            dialog: false,
            tag: false,
            dateChoice: '',
            expireTimeOption: {
                disabledDate(date) {
                    // disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
                    return date.getTime() >= Date.now();
                },
            },
            searchDate: [],
            direction: 'rtl',
            drawerDetail: false,
            drawerAddCustomer: false,
            drawerAddClue: false,
            tableRowId: '',
            tableRow: {},
        };
    },
    components: {
        Table,
        Detail,
        AddCustomer,
        AddClue,
    },
    created() {},
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            let data = {
                param: {
                    companyName: this.searchVal.companyName,
                },
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };
            if (
                sessionStorage.getItem('userType') == 8 ||
                sessionStorage.getItem('userType') == 9 ||
                sessionStorage.getItem('userType') == 10
            ) {
                data.param.departmentId = sessionStorage.getItem(
                    'departmentId'
                );
            } else {
                data.param.adminId = sessionStorage.getItem('adminId');
            }

            oldClueList(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.$refs.table.$data.tableData = res.data.list;
                    this.total = res.data.total;
                }
            });
        },
        // 线索详情
        onDetail(data) {
            this.drawerDetail = true;
            setTimeout(() => {
                this.$refs.detail.$data.data = data;
            }, 0);
        },
        // 转为客户
        onCustomer(data) {
            this.tableRow = data;
            this.drawerAddCustomer = true;
            setTimeout(() => {
                this.$refs.addCustomer.getData(data);
                this.$refs.addCustomer.$data.formData = data;
            }, 0);
        },
        //   修改搜索框的显示状态
        changeStatus() {
            this.tag = !this.tag;
        },
        // 保存并新建线索弹窗
        addClueDrawer(row) {
            this.tableRowId = row.id;
            let data = {
                param: {
                    id: row.id,
                },
            };
            getCustomerId(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.drawerAddCustomer = false;
                    this.drawerAddClue = true;
                    setTimeout(() => {
                        this.$refs.addClue.$data.customerDO = res.data;
                        // this.$refs.addClue.customerDealings(row.id);
                    }, 0);
                }
            });
        },
        // 生成线索
        onSubmitClue(data) {
            if (this.tableRow.clueCategory == 1) {
                data.param.lineDO.sourceCategory = 1;
            }
            customerAddClue(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.updateClue();
                    this.handleClose();
                }
            });
        },
        // 单选时间
        clickitem(e) {
            e === this.dateChoice
                ? (this.dateChoice = '')
                : (this.dateChoice = e);
            this.getData();
        },
        // 删除
        onDelete(row) {
            this.tableRowId = row.id;
            this.dialog = true;
        },
        update(id) {
            this.tableRowId = id;
            // console.log(456);
            this.updateClue();
        },
        updateClue() {
            let data = {
                param: {
                    clueDO: {
                        id: this.tableRowId,
                        dataStatus: 1,
                    },
                },
            };
            updateClue(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.handleClose();
                }
            });
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getData();
        },
        // 时间输入框变化
        expireTime() {
            if (this.searchDate && this.searchDate.length > 0) {
                this.dateChoice = '';
            }
            this.getData();
        },
        // 单选线索状态
        clickCustomerClueStatus(e) {
            e === this.searchVal.customerClueStatus
                ? (this.searchVal.customerClueStatus = '')
                : (this.searchVal.customerClueStatus = e);
            this.getData();
        },
        // 关闭
        handleClose() {
            this.drawerDetail = false;
            this.drawerAddCustomer = false;
            this.drawerAddClue = false;
            this.dialog = false;
            this.getData();
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.customer_box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.search-box {
    width: 100%;
    background: #fff;
    border-radius: 4px;
    margin-bottom: 16px;
    span {
        font-size: 12px;
        font-weight: 500;
        color: #333333;
        margin: 0 16px;
    }
    .search-one {
        padding: 10px 0 12px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .left {
            button {
                background: #2370eb;
                border-radius: 2px;
                border-color: #2370eb;
                margin-right: 16px;
            }
        }
        .right {
            background: #2370eb;
            border-radius: 2px;
            border-color: #2370eb;
            margin-right: 16px;
        }
    }
    .search-two {
        padding-bottom: 12px;
    }
}
.table-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 16px;
    .add {
        border-color: #d9d9d9;
        color: #333333;
    }
    p {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
    }
    button {
        font-size: 12px;
        padding: 0 7px 0 12px;
        height: 24px;
        background: #ffffff;
        border-radius: 2px;
        border: 1px solid #2370eb;
        cursor: pointer;
        color: #2370eb;
        margin-left: 16px;
    }

    button:hover {
        background: #2370eb;
        color: #ffffff;
        border-color: #2370eb;
    }
    .cancel:hover {
        background: #fff;
        color: #666;
        border-color: #e5e5e5;
    }
}
.table {
    flex: 1;
}
.page-box {
    background: #fff;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.dialog_info {
    width: 80%;
    margin: 25px auto 150px;
}
/deep/.el-dialog__footer {
    padding: 0;
    text-align: right;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid #eeeeee;
    padding-right: 18px;
}
</style>
