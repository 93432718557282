<template>
    <!-- <div class="add_customer">
    <div class="info_box">
      <div class="left_box">
        <p>基本信息</p>
        <div
          style="padding:20px 30px;height:calc(100% - 100px);overflow-y:auto"
        >
          <CustomerFrom ref="customerForm" :formData="formData"></CustomerFrom>
        </div>
      </div>
      <div class="right_box">
        <p>联系人</p>
        <div
          style="padding:20px 30px;height:calc(100% - 100px);overflow-y:auto"
        >
          <ContactsFrom ref="contactsForm"></ContactsFrom>
        </div>
      </div>
    </div>
    <div class="footer_btn">
      <el-button size="mini" round @click="onClose">取消</el-button>
      <el-button type="primary" size="mini" round @click="onSubmit(1)"
        >保存</el-button
      >
      <el-button type="primary" size="mini" round @click="onSubmit(2)"
        >保存并新建线索</el-button
      >
    </div>
  </div> -->
    <div class="add_cutomer_box">
        <div class="add_customer">
            <div class="left_form">
                <p class="wait_data"><span>待添加客户</span></p>
                <WaitData :basicData="formData"></WaitData>
            </div>
            <div class="right_form">
                <div class="customer_form">
                    <span>添加客户</span>
                    <div>
                        <span
                            @click="changeComp(true)"
                            :class="active ? 'active' : ''"
                            >基础信息</span
                        >
                        <span
                            @click="changeComp(false)"
                            :class="!active ? 'active' : ''"
                            >联系人</span
                        >
                    </div>
                </div>
                <div style="padding:0 30px">
                    <CustomerFrom
                        v-show="active"
                        ref="customerForm"
                    ></CustomerFrom>
                    <ContactsFrom
                        v-show="!active"
                        :formData="formData"
                        ref="contactsForm"
                    ></ContactsFrom>
                </div>
            </div>
        </div>
        <div class="footer_btn">
            <el-button size="mini" round @click="onClose">取消</el-button>
            <el-button
                type="primary"
                size="mini"
                round
                @click="onSubmit(1)"
                v-if="formData.clueCategory != 1"
                >保存</el-button
            >
            <el-button type="primary" size="mini" round @click="onSubmit(2)"
                >保存并新建线索</el-button
            >
        </div>
    </div>
</template>

<script>
import CustomerFrom from './customerFrom.vue';
import ContactsFrom from './contactsForm.vue';
import WaitData from './waitData.vue';
import { addCustomer } from '@/api/user/ae/customer.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            formData: {},
            customerDO: {},
            customerContactList: [],
            lineDO: {},
            active: true,
            tag: true,
        };
    },
    components: {
        CustomerFrom,
        ContactsFrom,
        WaitData,
    },
    created() {},
    methods: {
        getData(row) {
            this.formData = row;
            let data = JSON.parse(JSON.stringify(row));
            this.$refs.contactsForm.$data.customerContactList = [];
            this.$refs.customerForm.$data.customerTagList = [];
            this.$refs.customerForm.$data.customerDomainList = [];
            this.$refs.customerForm.$data.address = [];
            this.$refs.customerForm.$data.customerDO = {
                companyName: '',
                companyDomain: '',
                customerMainContactList: [],
                number: '',
                remark: '',
                primaryEmailBrand: '',
                contactRemark: '',
            };
            // 客户
            this.$refs.customerForm.$data.customerDO.companyName =
                data.companyName;
            this.$refs.customerForm.$data.customerDO.companyDomain =
                data.companyDomain;
            this.$refs.customerForm.$data.customerDO.formDataId = data.id;
            if (data.clueDomainList.length > 1) {
                this.$refs.customerForm.$data.customerDO.customerDomainList = [];
                data.clueDomainList.forEach((item, index) => {
                    if (index > 0) {
                        this.customerDomainList.push({ domain: item.domain });
                    }
                });
            } else {
                this.$refs.customerForm.$data.customerDO.customerDomainList = [
                    { domain: '' },
                ];
            }
            if (data.contactList.length == 0) {
                this.$refs.customerForm.$data.customerDO.customerMainContactList = [
                    {
                        contactWay: 1,
                        contactContent: '',
                    },
                ];
            } else {
                // console.log(this.customerDO);
                data.contactList.forEach((item) => {
                    this.$refs.customerForm.$data.customerDO.customerMainContactList.push(
                        {
                            contactWay: item.contactWay || 1,
                            contactContent: item.contactPhone,
                        }
                    );
                });
            }
            this.$refs.customerForm.$data.customerDO.number = data.userNo;
            this.$refs.customerForm.$data.customerDO.remark = data.remark;
            //联系人
            this.$refs.contactsForm.$data.formDataId = data.id;
            if (data.contactList.length > 0) {
                data.contactList.forEach((item) => {
                    this.$refs.contactsForm.$data.customerContactList.push({
                        contactName: item.contactName,
                        sex: 1,
                        customerContactWayDOList: [
                            {
                                contactWay: item.contactWay || 1,
                                contactContent: item.contactPhone,
                            },
                        ],
                    });
                });
                for (
                    let i = 0;
                    i <
                    this.$refs.contactsForm.$data.customerContactList.length -
                        1;
                    i++
                ) {
                    for (
                        let j = i + 1;
                        j <
                        this.$refs.contactsForm.$data.customerContactList
                            .length -
                            1;
                        j++
                    ) {
                        if (
                            this.$refs.contactsForm.$data.customerContactList[i]
                                .contactName ==
                            this.$refs.contactsForm.$data.customerContactList[j]
                                .contactName
                        ) {
                            this.$refs.contactsForm.$data.customerContactList[
                                i
                            ].customerContactWayDOList.push(
                                this.$refs.contactsForm.$data
                                    .customerContactList[j]
                                    .customerContactWayDOList[0]
                            );
                            this.$refs.contactsForm.$data.customerContactList.splice(
                                j,
                                1
                            );
                        }
                    }
                }
            } else {
                this.$refs.contactsForm.$data.customerContactList = [
                    {
                        contactName: '',
                        sex: 1,
                        customerContactWayDOList: [
                            {
                                contactWay: 1,
                                contactContent: '',
                            },
                        ],
                    },
                ];
            }
        },
        changeComp(active) {
            this.active = active;
        },
        changeStatus() {
            this.tag = !this.tag;
        },
        // 保存客户
        onSubmit(num) {
            // this.$emit("addClue", 123);
            // return;
            this.customerDO = this.$refs.customerForm.$data.customerDO;
            this.customerDO.provinceId = Number(
                this.$refs.customerForm.$data.address[0]
            );
            this.customerDO.cityId = Number(
                this.$refs.customerForm.$data.address[1]
            );
            this.customerContactList = this.$refs.contactsForm.$data.customerContactList;
            this.customerContactList.forEach((item) => {
                this.$choiceLabel.state.MaillabelList.forEach((itm) => {
                    item.contactTagList = itm;
                });
            });
            if (this.customerDO.companyName == '') {
                return this.$message.error('请输入公司名称');
            }
            if (this.customerDO.companyDomain == '') {
                return this.$message.error('请输入域名');
            }

            for (let i in this.customerContactList) {
                if (!this.customerContactList[i].contactName) {
                    return this.$message.error('请输入联系人');
                }
                for (let j in this.$refs.contactsForm.$data.customerContactList[
                    i
                ].customerContactWayList) {
                    if (
                        !this.$refs.contactsForm.$data.customerContactList[i]
                            .customerContactWayList[j].contactWay ||
                        !this.$refs.contactsForm.$data.customerContactList[i]
                            .customerContactWayList[j].contactContent
                    ) {
                        return this.$message.error('请输入完整联系人联系方式');
                    }
                }
            }
            let data = {
                param: {
                    customerDO: this.customerDO,
                    customerTagList: this.$choiceLabel.state.labelList,
                    customerDomainList: this.$refs.customerForm.$data
                        .customerDomainList,
                    customerContactList: this.customerContactList,
                    customerIntercourseList: this.formData.intercourseList,
                },
            };
            for (let i in this.customerDO.customerMainContactList) {
                if (this.customerDO.customerMainContactList[i].contactContent) {
                    data.param.customerDO = {
                        ...this.customerDO,
                        customerMainContactList: this.customerDO
                            .customerMainContactList,
                    };
                } else {
                    data.param.customerDO = {
                        ...this.customerDO,
                        customerMainContactList: [],
                    };
                }
            }
            // console.log(data);
            addCustomer(data).then((res) => {
                if (res.code == 200) {
                    this.active = true;
                    // console.log(456);

                    if (num == 1) {
                        this.onClose();
                        this.$emit('update', this.formData.id);
                    } else {
                        this.$refs.customerForm.$data.customerDO = {
                            companyName: '',
                            companyDomain: '',
                            customerMainContactList: [],
                            number: '',
                            remark: '',
                        };
                        this.$refs.customerForm.$data.formDataId = '';
                        this.$refs.contactsForm.$data.formDataId = '';
                        this.$refs.customerForm.$data.customerDomainList = [];
                        this.$refs.contactsForm.$data.customerContactList = [];
                        // 保存并新建线索
                        this.$emit('addClue', res.data);
                    }
                }
            });
        },
        onClose() {
            this.$refs.customerForm.$data.customerDO = {
                companyName: '',
                companyDomain: '',
                customerMainContactList: [],
                number: '',
                remark: '',
            };
            this.$refs.customerForm.$data.formDataId = '';
            this.$refs.contactsForm.$data.formDataId = '';
            this.$refs.customerForm.$data.customerDomainList = [];
            this.$refs.contactsForm.$data.customerContactList = [];
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
// .add_customer {
//   display: flex;
//   flex-direction: column;
//   width: 100%;
//   height: 100%;
//   .info_box {
//     flex: 1;
//     overflow: hidden;
//     display: flex;
//     justify-content: space-between;
//     background-color: #f0f2f4;
//     > div {
//       width: 49.5%;
//       height: 100%;
//       background-color: #fff;
//     }
//     p {
//       color: #2370EB;
//       font-weight: 600;
//       font-size: 14px;
//       height: 60px;
//       line-height: 60px;
//       padding-left: 17px;
//       box-sizing: border-box;
//       border-bottom: 1px solid #eee;
//     }
//   }
//   .footer_btn {
//     height: 50px;
//     line-height: 50px;
//     text-align: right;
//     border-top: 1px solid #e5e5e5;
//     padding-right: 30px;
//     button:nth-child(2) {
//       margin: 0 10px 0 20px;
//     }
//   }
// }
.add_cutomer_box {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
}
.add_customer {
    background-color: #f0f2f4;
    display: flex;
    width: 100%;
    flex: 1;
    justify-content: space-between;
    overflow: hidden;
}
.right_form {
    padding: 15px 0 !important;
}
.add_customer > div {
    width: 49.5%;
    background-color: #fff;
    box-sizing: border-box;
    padding: 15px 0;
    overflow-y: auto;
}
.add_customer > div p {
    font-size: 16px;
}
.wait_data {
    padding: 0 30px 5px 30px;
    border-bottom: 1px solid #eeeeee;
}
.wait_data span {
    border-radius: 1px;
    display: inline-block;
    color: #2370eb;
    line-height: 1.4;
    font-weight: 600;
}
.customer_form {
    padding: 0 30px 5px 30px;
    border-bottom: 1px solid #eeeeee;
}
.customer_form span {
    border-radius: 1px;
    display: inline-block;
    color: #fa8c15;
    font-weight: 600;
    line-height: 1.4;
}
.customer_form div {
    float: right;
    font-size: 14px;
}
.customer_form div span {
    color: #2370eb;
    position: relative;
    cursor: pointer;
}
.customer_form div span:first-child {
    margin-right: 50px;
}
.el-button--primary {
    background-color: #2370eb;
    border-color: #2370eb;
}
.customer_form .active:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 2px;
    background-color: #2370eb;
    bottom: -7px;
    left: 50%;
    transform: translateX(-50%);
}
.add_customer .business_info {
    margin-top: 25px;
    position: relative;
    margin-bottom: 25px;
}
.add_customer .business_info span:before {
    content: '';
    position: absolute;
    left: -10px;
    top: 0;
    height: 100%;
    width: 3px;
    background: #ffa802;
}
.add_customer .business_info .hide_btn {
    float: right;
    border-radius: 1px;
    border: 1px solid #2370eb;
    line-height: 1.5;
    font-size: 14px;
    padding: 0 8px;
    /* display: inline-block; */
    color: #2370eb;
    cursor: pointer;
}
.add_cutomer_box .footer_btn {
    height: 50px;
    line-height: 50px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
    padding-right: 30px;
}
.footer_btn button:nth-child(2) {
    margin: 0 10px 0 20px;
}
</style>
