<template>
  <div class="history" v-if="data.length > 0">
    <div v-for="item in data" class="list" :key="item.id">
      <span>{{ item.createTime || "- -" }}</span>
      <p>{{ handle(item) || "- -" }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    return {
      data: [],
    };
  },
  components: {},
  created() {},
  methods: {
    handle(item) {
      let str = item.content;
      let text = str
        .replace("%s", item.firstAdminName ? item.firstAdminName : "", 1)
        .replace("%s", item.secondAdminName ? item.secondAdminName : "", 2);
      return text;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  font-size: 12px !important;
}
.history {
  height: 50%;
  padding: 0 40px;
  overflow: hidden;
  .list {
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    color: #333333;
    line-height: 17px;
    position: relative;
    width: 100%;

    :before {
      content: "";
      position: absolute;
      left: -18px;
      top: 50%;
      transform: translateY(-50%);
      width: 8px;
      height: 8px;
      background: #2370eb;
      border-radius: 50%;
      margin-right: 16px;
    }
    p {
      margin-left: 32px;
      flex: 1;
      display: inline-block;
      word-break: break-all;
    }
  }
}
</style>
