<template>
  <div class="todo">
    <div class="todo-list" v-for="item in dataList">
      <div class="one" style="cursor: pointer;">
        <p><span>待办时间</span>{{ item.todoTime || "- -" }}</p>
        <p
          v-if="item.remindStatus == 1"
          style="width:35%;height:20px;margin-right:-5px;text-align:right;color:#333333"
        >
          <i class="iconfont icon-daiban1"></i>
          已提醒
        </p>
        <p
          v-if="item.remindStatus == 0"
          style="width:35%;height:auto;margin-right:-5px;text-align:right;color:#333333"
        >
          <span style="width:100%">
            <i class="iconfont icon-daiban1" style="color:#2370EB"></i>
            提前{{
              item.remindType == 1
                ? "15分钟"
                : item.remindType == 2
                ? "30分钟"
                : item.remindType == 3
                ? "1小时"
                : item.remindType == 4
                ? "2小时"
                : item.remindType == 5
                ? "1天"
                : "- -"
            }}</span
          >
        </p>
      </div>
      <div>
        <p><span>创建人</span>{{ item.adminName || "- -" }}</p>
      </div>
      <div>
        <p><span>待办标题</span>{{ item.todoTitle || "- -" }}</p>
      </div>
      <div>
        <p><span>待办内容</span>{{ item.todoContent || "- -" }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: [],
  data() {
    return {
      dataList: [],
    };
  },
  components: {},
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  font-size: 12px !important;
}
.todo {
  padding: 16px 25px 16px 40px;
  border-top: 1px dashed #eeeeee;
  .todo-list div {
    padding-bottom: 16px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    span {
      display: inline-block;
      width: 90px;
    }
  }
  .todo-list .one {
    display: flex;
    p:nth-child(1) {
      position: relative;
      width: 300px;
      :before {
        content: "";
        position: absolute;
        left: -18px;
        top: 50%;
        transform: translateY(-50%);
        width: 8px;
        height: 8px;
        background: #2370eb;
        border-radius: 50%;
      }
    }
  }
  .todo-list .bot {
    p:nth-child(1) {
      position: relative;
      color: #2370eb;
      span:nth-child(1) {
        position: absolute;
        left: -30px;
        top: 2px;
        font-size: 16px;
        border-radius: 50%;
        color: #2370eb;
        cursor: pointer;
      }
    }
  }
}
</style>
