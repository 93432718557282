<template>
    <div class="history">
        <div class="history_list">
            <div class="item" v-for="item in historyData" :key="item.id">
                <div class="add_explain">
                    <p class="last_p" v-if="item.customerId && item.customerId != 0">
                        来源：客户往来
                    </p>
                    <p class="last_p" v-if="item.customerId && item.customerId == 0">
                        来源：{{ $tableDataHandle.productCategory(item.productClue) }}
                    </p>
                </div>
                <p class="cicle">
                    <span>往来时间</span><span>{{ item.intercourseTime || '- -' }}</span>
                </p>
                <p>
                    <span>往来标题</span><span>{{ item.intercourseTitle || '- -' }}</span>
                </p>
                <p>
                    <span>跟进人</span><span>{{ item.adminName || '- -' }}</span>
                </p>
                <p>
                    <span>联系方式</span>
                    <span>{{
                        item.contactWay == 1
                            ? '电话'
                            : item.contactWay == 2
                            ? '微信'
                            : item.contactWay == 3
                            ? '邮箱'
                            : item.contactWay == 4
                            ? 'QQ'
                            : item.contactWay == 5
                            ? '短信'
                            : item.contactWay == 6
                            ? '拜访'
                            : item.contactWay == 7
                            ? '其他'
                            : item.contactWay == 8
                            ? '官网表单'
                            : item.contactWay == 9
                            ? '座机'
                            : '- -'
                    }}</span>
                </p>
                <p>
                    <span>往来内容</span><span>{{ item.intercourseContent || '- -' }}</span>
                </p>
                <p v-for="(item, index) in item.describeList" :key="index">
                    <span>往来说明</span><span>{{ item.describeContent || '- -' }}</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    props: [],
    data() {
        return {
            historyData: [],
        };
    },
    components: {},
    created() {},
    mounted() {},
    methods: {},
};
</script>

<style scoped>
.history {
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow: hidden;
}

.history_list {
    flex: 1;
    overflow-y: auto;
    font-size: 14px;
    color: #333;
}
.history_list .item {
    padding: 15px 40px 0;
    border-bottom: 1px dashed #eeeeee;
    position: relative;
}
.history_list .item:last-child {
    border-bottom: none;
}
.history_list .item p span:first-child {
    color: #666;
    margin-right: 20px;
    display: block;
    width: 60px;
    white-space: nowrap;
}
.history_list .item p {
    margin-bottom: 15px;
    display: flex;
    position: relative;
}
.history_list .item .cicle:before {
    content: '';
    position: absolute;
    left: -18px;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    background: #2370eb;
    border-radius: 50%;
}
.history_list .item p span:last-child {
    flex: 1;
    word-break: break-all;
}
.add_explain {
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 12px;
    text-align: right;
}
.add_explain .last_p {
    color: #2370eb;
    padding: 5px 10px;
    background: rgba(35, 112, 235, 0.1);
    border-radius: 2px;
    cursor: pointer;
    user-select: none;
}
.add_explain .first_p {
    display: inline-block !important;
    color: #ffa802;
    position: relative;
    z-index: 2;
    padding: 5px 10px;
    border: 1px solid #ffa802;
    cursor: pointer;
    user-select: none;
    border-radius: 2px;
}
.dialog .el-form {
    width: 65%;
    margin: 20px auto 150px;
}
.dialog >>> .el-textarea__inner {
    resize: none;
}
</style>
