<template>
    <div class="clue_detail">
        <div class="detail_title">
            <span class="circle">X</span>
            <div>
                <p>王先生</p>
                <p>负责人</p>
            </div>
            <span>最后跟进时间：2021/04/16 16：45 </span>
        </div>
        <div class="main_box">
            <div class="left_box">
                <p class="left_title">
                    <i class="iconfont icon-jibenxinxi"></i> 基本信息
                </p>
                <BasicInfo v-if="data.id" :basicData="data"></BasicInfo>
            </div>
            <div class="right_box">
                <el-collapse>
                    <el-collapse-item title="我的待办" name="1">
                        <template slot="title">
                            <i class="iconfont icon-daiban"></i>我的待办
                        </template>
                        <Todo ref="todo"></Todo>
                    </el-collapse-item>
                    <el-collapse-item title="往来记录" name="2">
                        <template slot="title">
                            <i class="el-icon-s-order"></i>往来记录
                        </template>
                        <Dealings ref="dealings"></Dealings>
                    </el-collapse-item>
                    <el-collapse-item title="线索历史" name="3">
                        <template slot="title">
                            <i class="iconfont icon-lishi"></i>线索历史
                        </template>
                        <History ref="history"></History>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>
    </div>
</template>

<script>
import { historyClueList } from '@/api/user/history.js';
import { dealingsList } from '@/api/user/dealings.js';
import { todoList } from '@/api/user/todo.js';
import BasicInfo from './basicInfo.vue';
import Todo from './todo.vue';
import Dealings from './dealings.vue';
import History from './history.vue';
export default {
    name: '',
    props: {},
    data() {
        return {
            data: {},
        };
    },
    watch: {
        data: {
            deep: true,
            handler(val) {
                val.id
                    ? (this.historyClueList(val.id),
                      this.dealingsList(val.id),
                      this.todoList(val.id))
                    : '';
            },
        },
    },
    components: {
        BasicInfo,
        Todo,
        Dealings,
        History,
    },
    created() {},
    methods: {
        historyClueList(id) {
            let data = {
                param: {
                    clueId: id,
                },
            };
            historyClueList(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.$refs.history.$data.data = res.data.list;
                }
            });
        },
        dealingsList(id) {
            let data = {
                param: {
                    clueId: id,
                },
            };
            dealingsList(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.$refs.dealings.$data.historyData = res.data.list;
                }
            });
        },
        todoList(id) {
            let data = {
                param: {
                    clueId: id,
                },
            };
            todoList(data).then((res) => {
                if (res.code == 200) {
                    this.$refs.todo.$data.dataList = res.data.list;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.clue_detail {
    font-size: 16px;
    color: #333;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .detail_title {
        padding: 20px 24px 25px 32px;
        border-bottom: 1px solid #eee;
        display: flex;
        color: #666;

        .circle {
            width: 40px;
            height: 40px;
            background-color: #2370eb;
            border-radius: 50%;
            text-align: center;
            line-height: 40px;
            color: #fff;
            display: block;
            margin-top: -8px;
            margin-right: 15px;
        }
        div {
            flex: 1;
            :first-child {
                margin-bottom: 10px;
            }
        }
        span {
            color: #666666;
        }
    }
    .main_box {
        flex: 1;
        overflow: hidden;
        display: flex;
        background-color: #f0f2f4;
        justify-content: space-between;
        > div {
            width: 49.5%;
            height: 100%;
            background-color: #fff;
            .left_title {
                width: 100%;
                height: 61px;
                line-height: 61px;
                font-size: 14px;
                border-bottom: 1px solid #ebeef5;
                padding-left: 17px;
                box-sizing: border-box;
                i {
                    color: #2370eb;
                    margin-right: 13px;
                }
            }
        }
        .right_box {
            overflow-y: auto;
        }
    }
    .icon-daiban {
        color: #ff5031;
        margin: 0px 13px 0px 17px;
        font-size: 18px;
    }
    .el-icon-s-order {
        color: #feb516;
        margin: 0px 13px 0px 17px;
        font-size: 18px;
    }
    .icon-lishi {
        color: #2f54eb;
        margin: 0px 13px 0px 17px;
        font-size: 18px;
    }
}
</style>
